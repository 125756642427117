import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { jsPDF } from "jspdf";
import { Categoria } from 'src/app/class/categoria';
import { Opcion } from 'src/app/class/opcion';
import { Resultado } from 'src/app/class/resultado';
import { Votacion } from 'src/app/class/votacion';
import { VotacionService } from 'src/app/services/votacion.service';
import autoTable from 'jspdf-autotable';
import { User } from 'src/app/class/user';
import { ExporterService } from 'src/app/services/exporter.service';
import { ActivatedRoute, Router } from '@angular/router';
import { OpcionResultado } from 'src/app/class/opcionResultado';
import { userPDF } from 'src/app/class/user-pdf';
import { userPDF2 } from 'src/app/class/user-pdf2';
import Swal from 'sweetalert2';
import { EmailService } from 'src/app/services/email.service';
@Component({
  selector: 'app-ver-resultado',
  templateUrl: './ver-resultado.component.html',
  styleUrls: ['./ver-resultado.component.css']
})
export class VerResultadoComponent implements OnInit {

  resultado : Resultado = {
    id: 0,
    emitidosHombre: 0,
    emitidosMujer: 0,
    noEmitidosHombre: 0,
    noEmitidosMujer : 0,
    porcentajeEmitidosHombre: 0,
    porcentajeEmitidosMujer: 0,
    porcentajenoEmitidosHombre: 0,
    porcentajenoEmitidosMujer: 0,
    porcentajepreferenciaBlanco: 0,
    porcentajepreferenciaNulas: 0,
    porcentajepreferenciasNormal: 0,
    porcentajepreferenciasNoUtilizadaNoVotante: 0,
    porcentajeTotal:0,
    porcentajeTotalEmitidos: 0,
    porcentajetotalHombreIncritas:0,
    porcentajetotalMujeresIncritas: 0,
    porcentajeTotalNoEmitido: 0,
    porcentajetotalPrefereciasPosibles: 0,
    preferenciaBlanco: 0,
    preferenciaNulas: 0,
    preferenciasNormal: 0,
    preferenciasNoUtilizadaNoVotante: 0,
    totalEmitidos:0 ,
    totalHombreIncritos: 0,
    totalIncritos: 0,
    totalMujeresIncritas: 0,
    totalNoEmitidos: 0,
    totalPrefereciasPosibles:0
  }

  files: FileList | null = null;
  userPDF : Array<userPDF> = [];
  userPDF2 : Array<userPDF2> = [];
  userPDF3 : Array<userPDF> = [];

  pdf1: Blob | string;
pdf3: Blob | string;
pdf2 : Blob | string;
  pdf4:string='';
  pdf5:string='';
  votacion : Votacion = {
    id : 0,
    descripcion : '',
    tipo:'',
    fechaApertura: new Date(),
    fechaCierre: new Date(),
    imagen: '',
    maximoOpciones : 0,
    nombre: '',
    participacionHombre : 0,
    participacionMujer : 0 ,
    porcentajeParticipacion : 0,
    totalIncritos : 0,
    totalIncritosHombre : 0 ,
    totalIncritosMujer : 0,
    votosHombre : 0 ,
    votosMujer : 0,
    votosRecibidos : 0,
    estado: ''


  }

  categoria1 : Categoria = {
    id:0,
    detalle: '',
    texto: ''

  }
  opciones : Opcion[] = [];

  opciones2 : OpcionResultado[] = [];
  user : User = {
    id:0,
    nombre : '',
    apellido: '',
    autorizado: true,
    celular : '',
    claveEnviada: '',
    claveGenerada : '',
    email : '' ,
    fechaVoto : new Date()  ,
    genero : '',
    grupo : '',
    password : '',
    passwordEnviar: '',
    rol : '',
    rut: '',
    numeroSerie : '',
    secret : '',
    username : '',
    verificacionRc : ''
      };

      user44 : User[] = [];
      myDataArray : User[]=[];
      myDataArray2 : User[]=[];
      myDataArray3 : User[]=[];
  constructor(
    private formBuilder : FormBuilder,
    private http: HttpClient,
    private activatedRoute: ActivatedRoute,
    private router : Router,
    private emailService: EmailService,
    private votacionService: VotacionService,
    private exporterService: ExporterService
  ) { }

  ngOnInit(): void {
    const id: number = this.activatedRoute.snapshot.params['id'];
    console.log('id que traigo',id);
    this.getOpciones(id);
    this.getVotacion(id);
    this.categoria(id);
    this.getResultados(id);
    this.inscritosPDF(id);
    this.votantesPDF(id);
    this.novotantesPDF(id);
    this.getUsers2();
    this.getUsers();

  }
  getUsers2() {
    this.votacionService.MFCE().subscribe(
      res => this.user44=res,
      err=> console.log(err)
    )
  }
   generarPDFApertura() {
    const random = Math.random().toString(36).substring(2, 5);
    const doc = new jsPDF();

    // Título
    doc.setFont('helvetica', 'bold');
    doc.text('Acta de Apertura', 80, 20);

    // Agregar logotipo
    const logo = new Image();
    logo.src = '../../../assets/images/VPTING_-removebg.png';
    doc.addImage(logo, 'JPEG', 5, 5, 30, 30,undefined,'FAST');

    // Primera tabla
    autoTable(doc, {
      html: '#estadistica',
      startY: 40, // Asegura que no se sobreponga con el contenido anterior
      theme: 'grid',
      styles: { font: 'helvetica', fontStyle: 'bold', halign: 'center' },
    });

    // Segunda tabla
    autoTable(doc, {
      html: '#datos',
      startY: (doc as any).lastAutoTable.finalY + 10, // Ubica la tabla debajo de la anterior
      theme: 'grid',
      styles: { font: 'helvetica', fontStyle: 'bold', halign: 'center' },
    });

    // Firma
    const firma = new Image();
    firma.src = '../../../assets/images/Signature.png';
    doc.addImage(firma, 'JPEG', 70, 170, 80, 80,undefined,'FAST');

    // Texto final
    doc.text('Firma del representante legal: Luis Bravo', 50, 235);

    // Guardar el archivo
    const fileName = `${random}_documento_de_apertura.pdf`;
    doc.save(fileName);
    console.log('PDF guardado:', fileName);
  }




  uploadDocumentos(event : any){

    const image = event.target.files[0];

    const formdata = new FormData();
    formdata.append('file', image);



    this.votacionService.uploadPDF(formdata).subscribe(
      (res) =>{
        Swal.fire('Atencion', 'Reporte cargado', 'success');
    console.log(res);
      },
      err => console.log(err)
     );
  }


  getUsers() {
    this.votacionService.ministro().subscribe(
      res => {
      if(res!=null){
      this.user=res
      }
      else{
       this.user.nombre='fairvoting'
       this.user.apellido='';
      }
    }
    ,
      err=> console.log(err)
    )
  }
  inscritosPDF(id:number){
    this.votacionService.incritos(id).subscribe(
      res => {this.userPDF=res




      },
      err=> console.log(err)
    )
    this.userPDF.length=0;
  }

  votantesPDF(id:number){
    this.votacionService.votantes(id).subscribe(
      res => {

        console.log('votantes que traigo ',res);
        this.userPDF2=res





      },
      err=> console.log(err)
    )
    this.userPDF2.length=0;
  }

  novotantesPDF(id:number){
    this.votacionService.novotantes(id).subscribe(
      res => {
        console.log('Que no votante traigo', res);
        this.userPDF3=res




      },
      err=> console.log(err)
    )
    this.userPDF3.length=0;
  }

  ImprimirincritosPDF(id: number) {
    const random = Math.random().toString(36).substring(2, 5);
    const doc2 = new jsPDF();
    const votacionNombre = this.votacion.nombre.trim();
    const nombreArchivo = `${random}_socios_inscritos_${votacionNombre.replace(/\s+/g, '_')}.pdf`;

    // Configuración general
    doc2.setFont('helvetica');
    doc2.setFontSize(12);

    // Fecha del reporte
    const fechaReporte = new Date().toLocaleString('es-ES', { hour12: false });

    // Agregar encabezado
    const logo = new Image();
    logo.src = '../../../assets/images/VPTING_-removebg.png';
    doc2.addImage(logo, 'JPEG', 10, 10, 30, 30,undefined,'FAST'); // Logotipo
    doc2.setFontSize(14);
    doc2.setFont('helvetica', 'bold');
    doc2.text('Reporte de Socios Inscritos', 50, 20);
    doc2.setFontSize(12);
    doc2.setFont('helvetica', 'normal');
    doc2.text(`Votación: ${votacionNombre}`, 50, 28);
    doc2.text(`Fecha de reporte: ${fechaReporte}`, 50, 35);

    // Tabla con estilos mejorados
    autoTable(doc2, {
      html: '#incritospdf',
      startY: 50,
      theme: 'grid',
      styles: {
        font: 'helvetica',
        fontStyle: 'normal',
        halign: 'center',
        fillColor: [240, 240, 240], // Fondo gris claro para filas
        textColor: [0, 0, 0], // Texto negro
        cellPadding: 3, // Espaciado interno de las celdas
      },
      headStyles: {
        fillColor: [0, 122, 255], // Fondo azul para encabezados
        textColor: [255, 255, 255], // Texto blanco
        fontStyle: 'bold',
      },
      alternateRowStyles: {
        fillColor: [245, 245, 245], // Fondo alternativo gris más claro
      },
      margin: { top: 50, left: 14, right: 14 }, // Márgenes ajustados
    });

    // Agregar un pie de página
    const pageHeight = doc2.internal.pageSize.height;
    const footerY = pageHeight - 10;
    doc2.setFontSize(10);
    doc2.setFont('helvetica', 'italic');
    doc2.text('Reporte generado automáticamente', 14, footerY);
    doc2.text(`Página 1 de 1`, doc2.internal.pageSize.width - 40, footerY);

    // Guardar archivo
    doc2.save(nombreArchivo);
    this.pdf2 = nombreArchivo;

    console.log(`PDF generado: ${nombreArchivo}`);
  }


  ImprimirVotantesPDF(id: number) {
    const random = Math.random().toString(36).substring(2, 5);
    const doc2 = new jsPDF();
    const votacionNombre = this.votacion.nombre.trim();
    const nombreArchivo = `${random}_socios_votantes_${votacionNombre.replace(/\s+/g, '_')}.pdf`;

    // Configuración general
    doc2.setFont('helvetica');
    doc2.setFontSize(12);

    // Fecha del reporte
    const fechaReporte = new Date().toLocaleString('es-ES', { hour12: false });

    // Agregar encabezado
    const logo = new Image();
    logo.src = '../../../assets/images/VPTING_-removebg.png';
    doc2.addImage(logo, 'JPEG', 10, 10, 30, 30,undefined,'FAST'); // Logotipo
    doc2.setFontSize(14);
    doc2.setFont('helvetica', 'bold');
    doc2.text('Reporte de Socios Votantes', 50, 20);
    doc2.setFontSize(12);
    doc2.setFont('helvetica', 'normal');
    doc2.text(`Votación: ${votacionNombre}`, 50, 28);
    doc2.text(`Fecha de reporte: ${fechaReporte}`, 50, 35);

    // Tabla con estilos mejorados
    autoTable(doc2, {
      html: '#votantespdf',
      startY: 50,
      theme: 'grid',
      styles: {
        font: 'helvetica',
        fontStyle: 'normal',
        halign: 'center',
        fillColor: [240, 240, 240], // Fondo gris claro para filas
        textColor: [0, 0, 0], // Texto negro
        cellPadding: 3, // Espaciado interno de las celdas
      },
      headStyles: {
        fillColor: [0, 122, 255], // Fondo azul para encabezados
        textColor: [255, 255, 255], // Texto blanco
        fontStyle: 'bold',
      },
      alternateRowStyles: {
        fillColor: [245, 245, 245], // Fondo alternativo gris más claro
      },
      margin: { top: 50, left: 14, right: 14 }, // Márgenes ajustados
    });

    // Agregar un pie de página
    const pageHeight = doc2.internal.pageSize.height;
    const footerY = pageHeight - 10;
    const totalPages = doc2.getNumberOfPages(); // Usamos el método correcto
    doc2.setFontSize(10);
    doc2.setFont('helvetica', 'italic');

    for (let i = 1; i <= totalPages; i++) {
      doc2.setPage(i); // Nos aseguramos de ajustar el pie en cada página
      doc2.text('Reporte generado automáticamente', 14, footerY);
      doc2.text(`Página ${i} de ${totalPages}`, doc2.internal.pageSize.width - 50, footerY);
    }

    // Guardar archivo
    doc2.save(nombreArchivo);
    this.pdf1 = nombreArchivo;

    console.log(`PDF generado: ${nombreArchivo}`);
  }
 // Método para manejar la carga de archivos
 onFileChange(event: any): void {
  this.files = event.target.files; // Obtén los archivos seleccionados
}
async enviarReportes(): Promise<void> {
  const formData = new FormData();
  for (let i = 0; i < this.files.length; i++) {
    formData.append('files', this.files[i], this.files[i].name);  // Asegúrate de agregar cada archivo con el nombre correcto
  }

    // Enviar correo con archivos adjuntos al backend
    this.emailService.sendEmailWithFiles(formData).subscribe(
      response => {
        console.log('Correo enviado exitosamente', response);
      },
      error => {
        console.error('Error al enviar correo', error);
      }
    );

}

  ImprimirNoVotantesPDF(id: number) {
    const random = Math.random().toString(36).substring(2, 5);
    const doc2 = new jsPDF();
    const votacionNombre = this.votacion.nombre.trim();
    const nombreArchivo = `${random}_socios_no_votantes_${votacionNombre.replace(/\s+/g, '_')}.pdf`;

    // Configuración general
    doc2.setFont('helvetica');
    doc2.setFontSize(12);

    // Fecha del reporte
    const fechaReporte = new Date().toLocaleString('es-ES', { hour12: false });

    // Agregar encabezado
    const logo = new Image();
    logo.src = '../../../assets/images/VPTING_-removebg.png';
    doc2.addImage(logo, 'JPEG', 10, 10, 30, 30,undefined,'FAST'); // Logotipo
    doc2.setFontSize(14);
    doc2.setFont('helvetica', 'bold');
    doc2.text('Reporte de Socios No Votantes', 50, 20);
    doc2.setFontSize(12);
    doc2.setFont('helvetica', 'normal');
    doc2.text(`Votación: ${votacionNombre}`, 50, 28);
    doc2.text(`Fecha de reporte: ${fechaReporte}`, 50, 35);

    // Tabla con estilos mejorados
    autoTable(doc2, {
      html: '#novotantespdf',
      startY: 50,
      theme: 'grid',
      styles: {
        font: 'helvetica',
        fontStyle: 'normal',
        halign: 'center',
        fillColor: [240, 240, 240], // Fondo gris claro para filas
        textColor: [0, 0, 0], // Texto negro
        cellPadding: 3, // Espaciado interno de las celdas
      },
      headStyles: {
        fillColor: [255, 69, 0], // Fondo naranja para encabezados
        textColor: [255, 255, 255], // Texto blanco
        fontStyle: 'bold',
      },
      alternateRowStyles: {
        fillColor: [245, 245, 245], // Fondo alternativo gris más claro
      },
      margin: { top: 50, left: 14, right: 14 }, // Márgenes ajustados
    });

    // Agregar pie de página dinámico
    const pageHeight = doc2.internal.pageSize.height;
    const footerY = pageHeight - 10;
    const totalPages = doc2.getNumberOfPages();
    doc2.setFontSize(10);
    doc2.setFont('helvetica', 'italic');

    for (let i = 1; i <= totalPages; i++) {
      doc2.setPage(i); // Nos aseguramos de ajustar el pie en cada página
      doc2.text('Reporte generado automáticamente', 14, footerY);
      doc2.text(`Página ${i} de ${totalPages}`, doc2.internal.pageSize.width - 50, footerY);
    }

    // Guardar archivo
    doc2.save(nombreArchivo);
    this.pdf3 = nombreArchivo;

    console.log(`PDF generado: ${nombreArchivo}`);
  }





  getOpciones(id: number) {

    this.votacionService.OpcionResultado(id).subscribe(
      res =>{ this.opciones2=res;
console.log('ocpiones'+res);


      }
      ,
      err=> console.log(err)
    )
  }
  categoria(id: number) {
    this.votacionService.getCategoria(id).subscribe(
      res =>{ this.categoria1=res;

        console.log('categoria id',this.categoria1.id);



      }
      ,
      err=> console.log(err)
    )
  }
  getVotacion(id: number) {
    this.votacionService.verVotacion(id).subscribe(
      res =>{ this.votacion=res;
console.log(res);


      }
      ,
      err=> console.log(err)
    )
  }

  generarExcel(){
    this.votacionService.excel().subscribe(
      res =>{
console.log(res);


      }
      ,
      err=> console.log(err)
    )
  }


  incritos(id:number){
    this.votacionService.incritos(id).subscribe(
      res => {this.myDataArray3=res
        this.exporterService.exportToExcel(this.myDataArray3,'incritos_'+this.votacion.nombre)
        this.myDataArray3.length=0;
      },
      err=> console.log(err)
    )
  }

  votantes(id:number){
    this.votacionService.votantes(id).subscribe(
      res => {this.myDataArray2=res
        this.exporterService.exportToExcel(this.myDataArray2,'votantes_'+this.votacion.nombre)
        this.myDataArray2.length=0;
      },
      err=> console.log(err)
    )
  }

  round(valor:number){
    return valor.toFixed(3);
  }

  novotante(id:number){
    this.votacionService.novotantes(id).subscribe(
      res => {this.myDataArray=res
        this.exporterService.exportToExcel(this.myDataArray,'no_votantes_'+this.votacion.nombre)
        this.myDataArray.length=0;
      },
      err=> console.log(err)
    )
  }

  generarPDF() {
    const random = Math.random().toString(36).substring(2, 5);
    const doc = new jsPDF();
    const votacionNombre = this.votacion.nombre.trim();
    const nombreArchivo = `${random}_escrutinio_${votacionNombre.replace(/\s+/g, '_')}.pdf`;

    // Configuración general
    doc.setFont('helvetica');
    doc.setFontSize(12);

    // Encabezado y logotipo
    const logo = new Image();
    logo.src = '../../../assets/images/VPTING_-removebg.png';
    doc.addImage(logo, 'JPEG', 5, 5, 30, 30, undefined, 'FAST');
    doc.text(`Escrutinio: ${votacionNombre}`, 40, 20);

    // Altura máxima permitida en la página
    const pageHeight = doc.internal.pageSize.height;
    const marginBottom = 20; // Margen inferior

    // Función para agregar tablas con manejo dinámico de espacio
    const agregarTabla = (selector: string, titulo: string) => {
      // Obtener posición actual
      let currentY = (doc as any).lastAutoTable?.finalY || 30;

      // Si no hay suficiente espacio para la tabla y su encabezado, se añade una nueva página
      const espacioNecesario = 60; // Espacio estimado para encabezado y primera fila de tabla
      if (currentY + espacioNecesario > pageHeight - marginBottom) {
        doc.addPage();
        currentY = 20; // Reiniciar posición en la nueva página
      }

      // Agregar título de la tabla
      doc.text(titulo, 14, currentY - 5);

      // Renderizar la tabla
      autoTable(doc, {
        html: selector,
        startY: currentY,
        theme: 'grid',
        styles: {
          font: 'helvetica',
          fontStyle: 'bold',
          halign: 'center',
          fillColor: [240, 240, 240], // Fondo gris claro para filas
          textColor: [0, 0, 0], // Texto negro
        },
        headStyles: {
          fillColor: [0, 122, 255], // Fondo azul para encabezados
          textColor: [255, 255, 255], // Texto blanco
          fontStyle: 'bold',
        },
        alternateRowStyles: {
          fillColor: [245, 245, 245], // Fondo alternativo gris más claro
        },
      });

      // Añadir un pequeño espacio después de cada tabla
      const espacioEntreTablas = 10; // Espacio entre tablas (ajústalo según necesidad)
      (doc as any).lastAutoTable.finalY += espacioEntreTablas; // Ajustar la posición para la siguiente tabla
    };

    // Agregar tablas
    agregarTabla('#votaciontodo', '');
    agregarTabla('#tableopciones', 'Opciones de Votación');
    agregarTabla('#contadorpersonas', 'Contador de Personas');
    agregarTabla('#porcentajepersonas', 'Porcentaje por Personas');
    agregarTabla('#preferencias', 'Preferencias');
    agregarTabla('#porcentajePreferencias', 'Porcentaje de Preferencias');

    // Verificar posición para agregar la firma y texto final
    let currentY = (doc as any).lastAutoTable?.finalY || 30;

    // Si no hay suficiente espacio, agregar una nueva página
    if (currentY + 70 > pageHeight - marginBottom) {
      doc.addPage();
      currentY = 20; // Reiniciar posición
    }

    // Página de firma
    const firma = new Image();
    firma.src = '../../../assets/images/Signature.png';
    doc.addImage(firma, 'JPEG', 65, currentY, 80, 50, undefined, 'FAST'); // Firma centrada
    doc.text('Firma del representante legal: Luis Bravo', 50, currentY + 70);

    // Guardar archivo
    doc.save(nombreArchivo);
    this.pdf4 = nombreArchivo;

    console.log(`PDF generado: ${nombreArchivo}`);
  }
  getResultados(id: number) {
    this.votacionService.getResultado(id).subscribe(
      res =>{ this.resultado=res;
console.log(res);


      }
      ,
      err=> console.log(err)
    )
  }

}
