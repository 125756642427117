<div style="text-align:center;" >
  <br>

  <h1 class="titulo">{{votacion1.nombre}} </h1>


  <div class="row">
    <div class="col-lg-2" style="text-align: center;">

    </div>

    <div class="col-lg-1" style="text-align: center;">


    </div>
    </div>
    <div   style="margin: 30px;">

      <div style="textAlign:center;
        backgroundColor: #33FF9F;
        margin:5px;
        borderRadius:15px" >
        <h2>Opciones escogidas {{cantidadRespuestas}}  de un máximo de {{votacion1.maximoOpciones}}</h2>

      </div>

    </div>
    <strong><h2><strong>  {{categoria.texto}} </strong></h2></strong>


    <mat-card class="card" style="border: 3px solid #87CEEB; margin: 30px; border-radius: 15px;" color="primary">
      <mat-card-content style="cursor: pointer" *ngFor="let item of opcionSorted(); index as i;">
        <br/>
        <br/>
        <div class="rowFairvoting">
          <div>
            <img [src]="item.seleccionada ? 'assets/images/marcada.png' : 'assets/images/marcar.png'" />
          </div>
          <div class="blockFaivoting">
            <div mat-card-avatar *ngIf="item.foto!=='.'">
              <img style="width: 50px;" src="{{url}}image/{{item.foto}}" />
            </div>
          </div>
          <div class="blockFaivoting">
            <strong>
              <label [innerHTML]="item.texto" style="color: #393969"></label>
            </strong>
          </div>
        </div>
      </mat-card-content>
      <br/>
    </mat-card>
  <br/>
  <br/>

  <div class="row">
    <div class="col-md-2"></div>
    <div class="col-md-8"><h4>Ingrese clave secreta de votaci&oacute;n: </h4></div>

    <div class="col-md-4"></div>

    <div class="col-md-4">
    <br>
    <mat-form-field class="example-full-width2">
      <mat-label>Ingrese la clave</mat-label>
      <input type="text" [(ngModel)]='clave' id="clave" matInput placeholder="******" name="clave"   maxlength="6" required="required"/>
    </mat-form-field>

    </div>
  <br>
  <br>
  </div>

  <br>
  <br>
  <br>

<div class="d-grid gap-2 col-2 mx-auto" >
  <button   mat-raised-button   style="border-radius: 10px; background-color: #8db600;padding: 5px; color: white;"   >&nbsp;&nbsp;VOTAR&nbsp;&nbsp;</button >
    <br>
    <button     mat-raised-button color="warn" style="border-radius: 10px;padding: 5px;"   >&nbsp;&nbsp;ANULAR&nbsp;&nbsp;</button>
  <br>
  <br>
  <br>
  <br>
  </div>

