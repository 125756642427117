import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from "src/environments/environment";
@Injectable({
  providedIn: 'root',
})
export class EmailService {
  private apiUrl = `${environment.api}email/send`; // URL del backend

  constructor(private http: HttpClient) {}

  sendEmailWithFiles(formData: FormData): Observable<any> {
    return this.http.post<any>(this.apiUrl, formData);
  }
}
