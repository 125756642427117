
<br>
<br>
<h3 align="center">Validar Claves Soporte</h3>
    <div align="center" class="mb-3">
      <mat-form-field class="login-form">
        <mat-label>Rut</mat-label>
      <input type="text"[(ngModel)]='rut' matInput name="rut"  [ngModelOptions]="{standalone: true}" id="rut" (input)="rut = formatRut(rut)"   size="10" maxlength="10" placeholder="Ingrese rut sin puntos ni guion" aria-describedby="emailHelp">
    </mat-form-field>
    <button type="submit" mat-raised-button style="background-color: #87CEEB;" (click)="validar()"><img src="/assets/images/consulta.png" alt="image">&nbsp;&nbsp;CONSULTAR&nbsp;&nbsp;</button>
    </div>



    <br>
    <br>
<div *ngIf="user.id!=0"  >
  <table class="table responsive-table" style="width: 100%;">
    <thead>
      <tr>
        <th scope="col">Nombre</th>
        <th scope="col">Clave</th>
        <th scope="col">Email</th>
        <th scope="col">Celular</th>
        <th scope="col">Enviar Clave</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>{{user.nombre+' '+user.apellido}}</td>
        <td>{{user.secret}}</td>
        <td>{{user.email}}</td>
        <td>{{user.celular}}</td>
        <td>
          <button type="submit" mat-raised-button style="background-color: #87CEEB;" (click)="EnviarClave(user.rut)">
            <img src="/assets/images/consulta.png" alt="image">&nbsp;&nbsp;Enviar&nbsp;&nbsp;
          </button>
        </td>
      </tr>
    </tbody>
  </table>
    </div>
      <br>
      <br>

