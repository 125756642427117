import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/class/user';
import { Votacion } from 'src/app/class/votacion';
import { VotacionService } from 'src/app/services/votacion.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-ver-papeleta-votacion',
  templateUrl: './ver-papeleta-votacion.component.html',
  styleUrls: ['./ver-papeleta-votacion.component.css']
})
export class VerPapeletaVotacionComponent implements OnInit {


  rut:string=''

  user : User = {
    id:0,
    nombre : '',
    apellido: '',
    autorizado: true,
    celular : '',
    claveEnviada: '',
    claveGenerada : '',
    email : '' ,
    fechaVoto : new Date()  ,
    genero : '',
    grupo : '',
    password : '',
    passwordEnviar: '',
    rol : '',
    rut: '',
    numeroSerie : '',
    secret : '',
    username : '',
    verificacionRc : ''
      };

  constructor( private http: HttpClient,


    private votacionService: VotacionService) { }

  ngOnInit(): void {
  }

  formatRut(value: string): string {
    if (!value) return '';

    // Remover caracteres que no sean números o 'k'/'K'
    value = value.replace(/[^0-9kK]/g, '');

    // Formatear el RUT en el formato xxxxxxxx-x
    if (value.length > 1) {
      const rut = value.slice(0, -1); // Todo menos el dígito verificador
      const dv = value.slice(-1).toUpperCase(); // Último carácter (Dígito verificador), siempre en mayúscula
      return `${rut}-${dv}`;
    }

    return value;
  }

  validar(){
    if(this.rut===''){
      Swal.fire('Estimado/a', 'el rut no puede ir vacio!', 'info');
    }
    else{
      this.votacionService.soporte(this.rut).subscribe( res => {
        this.user=res

      },
      err => {
        if(err.error.message == 'rut invalido'){
          Swal.fire('Estimado/a', 'el rut ingresado no es valido!', 'info');
         }
        if(err.error.message == 'votante no encontrado'){
          Swal.fire('Estimado/a', 'Su rut no se encuentra en nuestra base de datos comuniquese con su directiva!', 'info');
         }

      })
    }

  }

  EnviarClave(rut: string){

      this.votacionService.enviarClave(rut).subscribe( res => {

        Swal.fire('Estimado/a', 'la clave fue enviada!', 'success');
       console.log(res);

      },)
    }



}

